<template>
  <div class="body1">
    
    <div class="header">
      <div class="a1">welcome:
        <img src="icon/hutao.gif" alt="" style="height: 70px;margin-left: 30px;">
        <img src="icon/keli.gif" alt="" style="height: 70px;">
      </div>
      <div class="a2">
        <span @click="navigateTo('首页')">首页</span>
        <span @click="navigateTo('人物介绍')">人物介绍</span>
        <span @click="navigateTo('诡异介绍')">诡异介绍</span>
        <span @click="navigateTo('地图介绍')">地图介绍</span>
        <span @click="navigateTo('留言板')">留言板</span>
        <span @click="navigateTo('音乐欣赏')">音乐欣赏</span>
        <span @click="navigateTo('实验室')">实验室</span>

        
      </div>
      <div class="a3">
        <img src="icon/pika.gif" alt="" style="height: 50px;">
        <span>冬正月</span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "USER",
  data() {
    return {

    };
  },

  computed: {

  },
  created() {

  },

  mounted() {

  },
  methods: {
    navigateTo(page) {
      // 实现页面导航的逻辑，例如使用vue-router进行页面跳转
      console.log(page);
      // 假设使用了命名路由
      if (page == "人物介绍") {
        this.$router.push("/rw");
      } else if (page == "诡异介绍") {
        this.$router.push("/gy");
      } else if (page == "地图介绍") {
        this.$router.push("/mp");
      } else if (page == "留言板") {
        this.$router.push("/ly");
      } else if (page == "实验室") {
        this.$router.push("/sy");
      } else if (page == "音乐欣赏") {
        this.$router.push("/ab");
      } else if (page == "首页") {
        this.$router.push("/");
      }
    },

  }

};
</script>

<style>
html{
    overflow-y:scroll
}

.fade-leave-to {
  display: none;
}

.body1 {
  width: 100%;
  background-color: #f5f5f5;
  .header {
    position: absolute;
    color: black;
    top: 0;
    z-index: 1002;
    width: 100%;
    display: flex;
    height: 80px;
    line-height: 70px;
    background-color: white;
    cursor: pointer;


    .a1 {
      width: 25%;
      font-size: 36px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      text-align: left;
      margin-left: 5%;
      font-weight: lighter;
      display: flex;
      align-items: center
      
    }

    .a2 {
      width: 65%;
      display: flex;
      align-items: center;

      span {
        margin-left: 25px;
        font-size: 24px;
        font-weight: lighter;
        display: inline-block;
        transition: color 0.5s ease, box-shadow 0.5s ease;
        /* 添加 box-shadow 的过渡效果 */
        /* 初始状态下没有可见的边框，但可以通过 box-shadow 模拟一个透明的边框 */
        box-shadow: inset 0 0 0 0 rgba(241, 86, 112, 0);
        /* inset 表示内阴影，0 0 0 0 表示边框宽度为0 */
      }

      span:hover {
        color: rgb(231, 85, 6);
        /* 当鼠标悬停时，通过改变 box-shadow 的扩展半径来显示边框 */
        box-shadow: inset 0 5px 0 0 rgb(241, 86, 112);
      }
    }

    .a3 {
      width: 15%;
      display: flex;
      align-items: center;

      span {
        margin-left: 15px;
        font-size: 20px;
        font-weight: lighter;
        transition: color 0.5s ease;
      }

      span:hover {
        color: rgb(231, 85, 6);
      }
    }
  }
}
</style>