import request from "./request";

// 登录
export const selectperson = () => request.post("/selectperson");
// export const userLogin = (params) => request.post("/selectperson", params);
// 修改全部评论
export const selectmsg = () => request.post("/test2");
// 查询音乐
export const selectmusic = () => request.post("/selectmusic");
//评论信息
export const selectct1 = () => request.post("/selectc1");
export const selectct2 = () => request.post("/selectc2");
//学院信息
export const sa1 = () => request.post("/sa1");
//诡异信息
export const sd1 = () => request.post("/sd1");


