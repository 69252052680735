<template>
    <div class="body1">
        <UseLink />
        <div class="tap">
            <div class="tab">
                <span></span>
                <p>基础信息</p>
            </div>
            <div class="header1">
                <p>姓名：<span>{{ list1.name }}</span></p>
                <p>性别：<span>{{ list1.sex }}</span></p>
                <p>归属：<span>{{ list1.hone }}</span></p>
                <p>能力：<span>{{ list1.skill }}</span></p>

            </div>
            <div class="tab">
                <span></span>
                <p>图文描述</p>
            </div>
            <div style="height: 350px;text-align: left;">
                <img :src="list1.src" alt="" height="85%" style="margin:20px 50px;">
                <div class="goo">点赞数：<span style="font-size: 28px;color: #ff4c4c;">{{ num }}</span>
                    <span style="margin-left: 50px;">为ta点赞</span>
                    <img src="icon/点赞1.png" alt="" v-if="AA" height="30px" @click="jia(list1)">
                    <img src="icon/点赞2.png" alt="" v-else height="30px" @click="jian(list1)">
                </div>
            </div>
            <div class="tab">
                <span></span>
                <p>具体信息</p>
            </div>
            <div style="text-align: left;">
                <p style="font-size: 24px;font-weight: lighter;padding:10px 50px;">
                    {{ list1.comment }}已有<span style="color: #ff4c4c;">{{ num }}</span>人为其点赞。</p>

            </div>
            <div class="tab">
                <span></span>
                <p>最终结论</p>
            </div>
            <div style="height: 200px;text-align: left;">
                <p style="font-size: 37px;font-weight: lighter;padding:10px 50px;"> {{ list1.msg }}!
                </p>
            </div>
        </div>
        <UseFoot />
    </div>
</template>
<script>
import { selectperson } from "@/API/api";
export default {
    name: "USER",
    data() {
        return {
            id: "",
            foundItem: null, // 用于存储找到的对象
            name: "海带怪物",
            zhonzu: "诡异",
            level: "梦魇级",
            nengli: "催眠",
            img: "@/assets/monster/梦魇之王.png",
            list: [],
            list1: [],
            AA:true,
            num:""

        };
    },
    computed: {

    },
    created() {
        this.id = this.$route.query.id;
        console.log(this.id); // 输出传递的 idy 值
        // this.findItemByIdy(this.id);
        this.selectperson();

    },

    mounted() {

    },
    methods: {
        jia(a) {
            console.log(a);
            this.AA=false
            this.num++

        },
        jian(a) {
            console.log(a);
            this.AA=true
            this.num--

        },
        selectperson() {
            console.log("传过来的id" + this.id);

            selectperson({}).then((res) => {
                this.list = res.data.data
                this.list1 = this.list.find(user => user.id == this.id);
                console.log(this.list1);
                this.num=this.list1.num

            });
        },
    }
};
</script>
<style>
html {
    min-height: 101%;
}

.body1 {
    overflow: hidden;

    .tap {
        width: 60%;
        margin: 20px auto;
        background-color: white;
        /* overflow: hidden; */
        margin-top: 100px;
        border-radius: 10px;

        .tab {
            width: 100%;
            height: 50px;
            background-color: white;
            display: flex;
            align-items: center;
            margin-top: 50px;
            padding-top: 20px;


            p {
                font-size: 30px;
                font-weight: lighter;
            }

            span {
                display: inline-block;
                width: 5px;
                height: 40px;
                background-color: pink;
                margin: 0 20px 0 50px;
            }
        }

        .header1 {
            width: 90%;
            /* height: 120px; */
            margin: 10px auto;
            /* background-color: wheat; */
            text-align: left;

            p {
                font-size: 24px;
                font-weight: lighter;
                margin: 0px;
                margin-top: 10px;
            }


        }

        .goo {
            padding-left: 50px;
            font-size: 24px;
            font-weight: lighter;
            display: flex;
            height: 40px;
            /* background-color: pink; */
            align-items: center;
        }
    }
}
</style>