<template>
    <div class="body1">
        <div class="foot">
            <div class="fo1">
                <div style="display: flex;align-items: center;">
                    <img src="icon/zl.gif" alt="" style="height: 55px;border-radius: 4px;">
                    <span
                        style="margin-left: 20px;font-family: 'Courier New', Courier, monospace;font-size: 34px;font-weight: 600;">冬正月の奇妙体验</span>
                </div>
                <p style="font-size: 18px;font-weight: lighter;">
                    这个世界其实从不曾有一个人能取代另一个人的位置，所谓的取代，只是以前的那个人被遗忘。</p>

            </div>
            <div class="fo2">
                <p style="font-size: 16px;">友链申请 免责声明 隐私政策 关于我们</p>
                <p style="font-size: 16px">Copyright © 2023 · 基尼太妹 · 由鸽鸽编程强力驱动.豫ICP备114514号-1</p>
                <div class="fox">
                    <div style="margin-left: 40%;">
                        <img src="icon/微信.png" alt="">
                    </div>
                    <div> <img src="icon/QQ.png" alt=""></div>
                    <div><img src="icon/微薄.png" alt=""></div>
                    <div><img src="icon/邮箱.png" alt=""></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "USER",
    data() {
        return {

        };
    },
    computed: {

    },
    created() {

    },

    mounted() {

    },
    methods: {


    }

};
</script>

<style>
.body1 {

    .foot {
        width: 100%;
        height: 150px;
        background-color: white;
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
      

        .fo1 {
            width: 30%;
            height: 100%;
            /* background-color: pink; */
            text-align: left;
            padding-top: 30px;
        }

        .fo2 {
            width: 60%;
            height: 100%;
            font-weight: lighter;
            /* background-color: lawngreen; */
            padding-top: 30px;

            p {
                margin: 10px;
            }

            .fox {
                width: 100%;
                height: 40px;
                /* background-color: aqua; */
                display: flex;


                div {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: rgb(180, 179, 179);
                    display: flex;
                    margin-left: 12px;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 30px;
                    }
                }
            }
        }

    }
}
</style>