
import axios from "axios";

const request = axios.create({
  // baseURL: "http://localhost:8086/",
  baseURL: "http://106.14.187.169:8086/",
  timeout: 300000,
})
// 请求拦截
request.interceptors.request.use(
  (config) => {
    //部分接口需要拿到token
    let token = sessionStorage.getItem("token")
    if (token) {
      config.headers.Authorization = token; 
    }
    if (config.method.toUpperCase() === 'OPTIONS') {  
      // 如果是 OPTIONS 请求，则直接返回配置，不执行额外的逻辑  
      return config;  
    }  
    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)

request.interceptors.response.use((response) => {
  //  if(response.data=="401"){
  //   localStorage.removeItem("token");
  //   window.location.reload();
  //  }
  return response;
})

export default request