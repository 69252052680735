import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)
const routes = [
  {
    path: "/",
    name: "homelike",
    components: { AppPage: () => import("@/views/HomeLike.vue") },
  },
  {
    path: "/rw",
    name: "rw",
    components: { AppPage: () => import("@/components/novel/RenWU.vue") },
  },
  {
    path: "/ab",
    name: "ab",
    components: { AppPage: () => import("@/components/novel/AboutOther.vue") },
  },
  {
    path: "/ly",
    name: "ly",
    components: { AppPage: () => import("@/components/novel/LiuYan.vue") },
  },
  {
    path: "/gy",
    name: "gy",
    components: { AppPage: () => import("@/components/novel/GuiYi.vue") },
  },
  {
    path: "/sy",
    name: "sy",
    components: { AppPage: () => import("@/components/novel/ShiYan.vue") },
  },
  {
    path: "/mp",
    name: "mp",
    components: { AppPage: () => import("@/components/novel/OneDay.vue") },
  },
  {
    path: "/user",
    name: "user",
    components: { AppPage: () => import("@/components/novel/user.vue") },
  },
  {
    path: "/foot",
    name: "foot",
    components: { AppPage: () => import("@/components/novel/foot.vue") },
  },
  {
    path: "/test",
    name: "test",
    components: { AppPage: () => import("@/components/novel/OneTest.vue") },
  },
  {
    path: "/login",
    name: "login",
    components: { AppPage: () => import("@/views/LoginView.vue") },
  },
]
const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    // 如果 savedPosition 存在，则使用它
    // 否则，滚动到页面顶部
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})
// 向 router 上添加一个，重新实例化 VueRouter 的方法
router.reloadRouter = function () {
  router.matcher = new VueRouter({
    mode: "history",
    routes,
  }).matcher
}
//一直点击路由报错解决方案
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}
export default router
